import { useStaticQuery, graphql } from "gatsby"

export const usePageSpecificSchemaQuery = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query PageSpecificSchemaQuery {
        wpgraphql {
          nexvelSchemaMarkup {
            nexvelschema {
              blogPageUrl
              businessName
              aboutPageUrl
              contactPageUrl
              businessType
              logo {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return { wpgraphql }
}
